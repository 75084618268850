var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "MuiModal",
    {
      staticClass: "InvoicePaymentsModal",
      attrs: {
        title: _vm.$labels.InvoiceDetailsTab.payments.modal.add.title,
        header: _vm.headerObject,
        "data-test-id": "payment-modal",
      },
      on: {
        "modal-closed": function ($event) {
          return _vm.$emit("on:close")
        },
      },
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "body" }, slot: "body" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { fixed: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c(
            "ui-validate",
            {
              staticClass: "d-flex flex-wrap mx-3",
              on: { status: _vm.isFormAllValid },
            },
            [
              _c(
                "div",
                { staticClass: "w-100 d-flex", attrs: { name: "firstRow" } },
                [
                  _c("MuiAlgoliaSelect", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate.select",
                        value: {
                          isRequired: true,
                        },
                        expression:
                          "{\n            isRequired: true\n          }",
                        modifiers: { select: true },
                      },
                    ],
                    staticClass: "my-2 pl-0",
                    class: _vm.isV6Version ? "w-100" : "col-6",
                    attrs: {
                      index: _vm.ALGOLIA_INDEXES.paymentMethods,
                      title: (method) => method.name,
                      label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.payment_method}*`,
                      placeholder:
                        _vm.$labels.InvoiceDetailsTab.payments.modal
                          .payment_method_plaheholder,
                      "path-value": _vm.isV6Version ? "uuid" : "id",
                      name: "paymentMethod",
                      "data-test-id": "payment_method-select",
                    },
                    on: { selected: _vm.selectAlgolia },
                    model: {
                      value: _vm.optionSelected,
                      callback: function ($$v) {
                        _vm.optionSelected = $$v
                      },
                      expression: "optionSelected",
                    },
                  }),
                  !_vm.isV6Version
                    ? _c("ui-datetimepicker", {
                        staticClass: "col-6 my-2 pr-0",
                        attrs: {
                          size: _vm.SIZES.small,
                          label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.date}*`,
                          skiptime: "",
                          "data-test-id": "date-input",
                        },
                        domProps: { date: _vm.moment(_vm.date) },
                        on: {
                          datechanged: ({ detail }) => (_vm.date = detail),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c("ui-text-input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      isRequired: true,
                      isPattern: {
                        pattern: _vm.NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,
                        message:
                          _vm.$labels.InvoiceDetailsTab.payments.modal
                            .amount_format_error,
                      },
                      isAmountOutOfRange: (value) => {
                        const limits = {
                          min: _vm.minAmount,
                          max: _vm.maxAmount,
                        }

                        return {
                          isValid: _vm.isAmountValid(value),
                          message: _vm.template(
                            _vm.$labels.InvoiceDetailsTab.payments.modal
                              .amount_out_of_range
                          )(limits),
                        }
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isPattern: {\n            pattern: NEGATIVE_OR_POSITIVE_DECIMAL_PATTERN,\n            message: $labels.InvoiceDetailsTab.payments.modal.amount_format_error,\n          },\n          isAmountOutOfRange: (value) => {\n            const limits = { min: minAmount, max: maxAmount };\n\n            return {\n              isValid: isAmountValid(value),\n              message: template($labels.InvoiceDetailsTab.payments.modal.amount_out_of_range)(limits),\n            };\n          },\n        }",
                    modifiers: { input: true },
                  },
                ],
                staticClass: "col-12 px-0 my-2",
                attrs: {
                  value: _vm.amount,
                  type: _vm.INPUT_TYPES.number,
                  label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.amount}*`,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.payments.modal
                      .amount_placeholder,
                  name: "amount",
                  "data-test-id": "amount-input",
                },
                on: { changevalue: ({ detail }) => (_vm.amount = detail) },
              }),
              _c("ui-text-area", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate.input",
                    value: {
                      isRequired: true,
                      isMinLength: {
                        message: "This field cannot be blank",
                        length: 1,
                      },
                      isMaxLength: {
                        message: `The number of characters allowed is ${_vm.maxTextAreaLength}`,
                        length: _vm.maxTextAreaLength,
                      },
                    },
                    expression:
                      "{\n          isRequired: true,\n          isMinLength: {\n            message: 'This field cannot be blank',\n            length: 1,\n          },\n          isMaxLength: {\n            message: `The number of characters allowed is ${maxTextAreaLength}`,\n            length: maxTextAreaLength,\n          },\n        }",
                    modifiers: { input: true },
                  },
                ],
                staticClass: "d-block my-2 w-100",
                attrs: {
                  value: _vm.description,
                  placeholder:
                    _vm.$labels.InvoiceDetailsTab.payments.modal
                      .comments_placeholder,
                  label: `${_vm.$labels.InvoiceDetailsTab.payments.modal.comments}* (${_vm.textAreaCharsCount} characters left)`,
                  name: "description",
                  "data-test-id": "description-input",
                  maxlength: _vm.maxTextAreaLength,
                },
                on: { changevalue: _vm.textAreaManager },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-end p-3",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("CancelButton", {
            attrs: { "data-test-id": "cancel-button" },
            on: {
              click: function ($event) {
                return _vm.$emit("on:close")
              },
            },
          }),
          _c(
            "ui-button",
            {
              attrs: {
                disabled: !_vm.isAddAllowed,
                "data-test-id": "add-button",
              },
              on: { clickbutton: _vm.onAddPayment },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$labels.InvoiceDetailsTab.payments.actions.add) +
                  " "
              ),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }